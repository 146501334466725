import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const ConfigurationSettings = () => {
  return (
    <LayoutComponent>
      <h3>Configuration Settings</h3>
      <div className="testimonials">
        <h5>Note</h5>
        <p>
          You must be a site admin or project admin to manage the configuration
          settings for your project.
        </p>
      </div>
      <ol>
        <li>
          Click Configuration in the upper-right corner of the screen to access
          the configuration settings.
        </li>
        <li>
          There are two sections for you to customize: GENERAL, STATUSES.
        </li>
      </ol>
    </LayoutComponent>
  )
}

export default ConfigurationSettings
